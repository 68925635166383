.text-area-field {
  &__container {
    @include custom-scrollbar(0);
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: 7px;
    padding: 15px 10px 15px 15px;
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
    font-family: $helvetica;
    color: $colour-heathered-grey;
  }

  &__input {
    resize: none;
    width: 100%;
    height: 100%;
    min-height: 88px;
    max-height: 268px;
    font-size: 15px;
    padding-right: 10px;
    border: 0;
    outline: none;
    color: $colour-cocoa-brown;
    font-family: inherit;

    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      font-family: $helvetica;
      color: $colour-heathered-grey;
    }
  }
}
