.new-search-empty {
  padding: 100px 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 212px;
    height: 148px;

    img {
      width: 100%;
    }
  }

  &__title {
    margin: 15px 0;
    font-size: 16px;
    font-weight: 500;
    color: #2f1f19;
  }

  &__link {
    background-color: $colour-neon-blue;
    font-size: 15px;
    text-decoration: none;
    color: $colour-white;
    padding: 10px 15px;
    border-radius: 14px;
  }
}
