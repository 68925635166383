$primary-color: #936447;

.item-card {
  &_residence {
    padding-bottom: 6px;

    &-preview {
      &-cover {
        width: 100%;
        height: 147px;
        background-size: cover;
        background-position: center center;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }

    &-price {
      display: inline-block;
      padding: 5px;
      font-weight: bold;
      font-size: 15px;
      line-height: 1.3;
      color: #2f1f19;
      z-index: 3;
      white-space: nowrap;

      span:first-child {
        margin-right: 5px;
      }
    }

    .title_block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 2px 15px 2px 5px;

      .abs_block {
        position: relative;
      }
    }

    &-title {
      padding: 0 0 0 5px;
      max-height: 33.6px;
      font-size: 14px;
      line-height: 1.21;
      // stylelint-disable-next-line
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: #2f1f19;
      margin: 0;
      font-weight: 500;
    }

    & &-params {
      font-size: 14px;
      line-height: 16px;
      padding: 0;
      margin: 0 15px 0 7px;
      bottom: 0;
      left: 0;
      right: 0;
      color: #2f1f19;

      > div {
        @include ellipsis;
        position: relative;
        padding-top: 2px;
        padding-left: 17px;

        &:is(.item-card_residence-params-deadline) {
          padding-left: 21px;
        }

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 4px;
          left: 3px;
          background-repeat: no-repeat;
          background-size: contain;
          transform: translateZ(0);
        }
      }

      &-location {
        &::before {
          width: 12px;
          height: 12px;
          background-image: url('/static/images/residences/icon-location.svg');
        }
      }

      &-deadline {
        &::before {
          width: 12px;
          height: 12px;
          background-image: url('/static/images/residences/icon-key.svg');
        }
      }
    }
  }
}
