$icon-path: '../../../../public/static/images/new_search/icons/icon-reset.svg';

.block-with-margin {
  margin: 15px 15px 0;
  width: calc(100% - 30px) !important;
}

.filter-modal {
  z-index: 100;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 7px 7px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding-bottom: env(safe-area-inset-bottom);

  &__overlay {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
  }

  &__header {
    text-align: center;
    position: relative;
    padding: 30px 15px 0;
    font-weight: 500;
    font-size: 16px;

    &__close-btn {
      width: 15px;
      height: 15px;
      position: absolute;
      top: 30px;
      right: 23px;
      cursor: pointer;
      background-color: #fff;
    }
  }

  &__content {
    padding: 15px;
  }

  &__apply-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border-radius: 14px;
    background-color: $colour-neon-blue;
    font-size: 15px;
    color: $colour-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    transition: opacity 0.3s ease-in-out 0.3s;
  }

  &__radio {
    padding-left: 0;
    padding-right: 0;
  }

  &__checkbox-label {
    padding-left: 0;
  }

  &__action-buttons {
    display: flex;
    gap: 15px;

    &_with-top-m {
      margin-top: 15px;
    }
  }
}
