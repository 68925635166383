.profile-header {
  background-color: $colour-white;
  border-bottom: 1px solid $colour-grey-wild-sand;

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    position: relative;

    &-back-button {
      position: absolute;
      left: 0;
      height: 45px;
      width: 40px;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 0;
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &--large-space {
      height: 60px;
    }

    &-title {
      color: $colour-cocoa-brown;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__chips {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 12px;

    button:first-child {
      margin-left: 15px;
      border-radius: 7px 0 0 7px;
    }

    button:last-child {
      margin-right: 15px;
      border-radius: 0 7px 7px 0;
    }
  }
}
