@keyframes showFromBottom {
  0% {
    transform: translateY(120%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes hideToBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(120%);
  }
}

@keyframes showFromRight {
  0% {
    transform: translateX(120%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes hideToRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(120%);
  }
}

@keyframes showFromTop {
  0% {
    transform: translateY(-140%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hideToTop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-140%);
  }
}

@keyframes addBorderTop {
  0% {
    border-top: 0 solid transparent;
  }

  100% {
    border-top: 1px solid #eae5e1;
  }
}

@keyframes addBackground {
  0% {
    background: rgba(0, 0, 0, 0.1);
  }

  100% {
    background: rgba(0, 0, 0, 0.4);
  }
}

.modal {
  &__portal {
    &--transparent {
      opacity: 0;
    }

    &--closed-right {
      .modal__overlay,
      .modal__content {
        animation: hideToRight 0.3s forwards ease-in-out;
      }
    }

    &--opened-right {
      .modal__overlay,
      .modal__content {
        animation: showFromRight 0.3s forwards ease-in-out;
      }
    }

    &--closed-bottom {
      .modal__overlay,
      .modal__content {
        animation: hideToBottom 0.3s forwards ease-in-out;
      }

      .modal__overlay {
        &--report {
          animation: addBackground 0.3s forwards ease-in-out;
        }

        &--transparent {
          animation: addBackground 0.3s forwards ease-in-out;
        }
      }
    }

    &--opened-bottom {
      .modal__overlay,
      .modal__content {
        animation: showFromBottom 0.3s forwards ease-in-out;
      }

      .modal__overlay {
        &--report {
          animation: addBackground 0.3s forwards ease-in-out;
        }

        &--transparent {
          animation: addBackground 0.3s forwards ease-in-out;
        }
      }
    }

    &--closed-top {
      .modal__content {
        animation: hideToTop 0.3s forwards ease-in-out;
      }
    }

    &--opened-top {
      .modal__content {
        animation: showFromTop 0.5s forwards ease-in-out;

        &--header-menu {
          animation: addBorderTop 0.5s forwards ease-in-out;
        }
      }
    }
  }

  &__content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100.1%; // fix bug with url bar in Chrome Mobile
    background-color: $colour-white;

    &--with-scroll {
      overflow-y: scroll;
    }

    &--alert {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      width: 80vw;
      border-radius: 7px;
      background: #fff;
      overflow-y: hidden;
    }

    &--header-menu {
      position: relative;
      left: 0;
      right: 0;
      height: fit-content;
      background: #fff;
      overflow-y: hidden;
    }

    &--promotion {
      width: 196px;
      border-radius: 5px;
    }

    &--contacts-info,
    &--report,
    &--electronic-banners,
    &--bordered {
      top: unset;
      position: fixed;
      overflow-y: hidden;
      border-radius: 7px 7px 0 0;
      height: auto;
    }

    &--map {
      position: relative;
      height: auto;
    }

    &-params,
    &-apartments,
    &-contacts {
      position: absolute;
      height: auto;
      bottom: 0;
      top: auto;
      border-radius: 7px 7px 0 0;
    }

    &-params {
      height: 90%;
    }

    &--side-menu,
    &--grey-background {
      background: #f6f4f1;
      overflow-y: auto;

      &-container {
        height: 600px;
      }

      &-background-tertiary {
        background: #f8f8f8;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    font-family: $helvetica;
    background-color: $colour-white;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    &--alert {
      z-index: 9999;
      background: rgba(0, 0, 0, 0.4);
    }

    &--header-menu {
      background: rgba(0, 0, 0, 0.4);
      z-index: 9;
      top: 70px;
    }

    &--map,
    &-params,
    &-contacts,
    &-apartments {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &--contacts-info,
    &--report,
    &--electronic-banners,
    &--transparent {
      background: rgba(0, 0, 0, 0.4);
      z-index: 100001;
    }

    &--side-menu {
      z-index: 96;
    }

    &--calc-info {
      z-index: 100001;
    }
  }

  &__portal {
    -webkit-overflow-scrolling: touch;
  }

  &__body--open {
    overflow: hidden;
  }
}
