$border: 1px solid $colour-grey-wild-sand;

.terminal-modal {
  color: $colour-cocoa-brown;

  &__user-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: $colour-white;
    color: $colour-heathered-grey;
    border-bottom: $border;

    span {
      display: block;
      font-size: 14px;

      &:last-child {
        width: 100%;
        padding: 10px;
        color: #ca1016;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        border-radius: 7px;
        background-color: $colour-background-tertiary;
      }
    }
  }

  &__content {
    padding: 20px 15px;
    border-top: $border;
    border-bottom: $border;
    background-color: $colour-white;

    &-title {
      font-size: 15px;
      font-weight: 700;
    }

    &-list {
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      margin: 25px 0 15px;
      border-bottom: $border;

      &-item {
        display: flex;
        gap: 10px;
        min-height: 30px;
        align-items: center;
        margin-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        position: relative;

        &__step {
          height: 30px;
          min-width: 30px;
          border-radius: 50%;
          border: 1px solid $colour-grey-wild-sand;
          color: $colour-heathered-grey;
          font-size: 14px;
          font-weight: 400;
          position: relative;
        }

        &__step span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &__title {
          align-items: center;
        }
      }

      &-item:first-child {
        margin-bottom: 10px;

        &::before {
          content: '';
          position: absolute;
          background-color: $colour-grey-wild-sand;
          left: 15px;
          bottom: -10px;
          width: 1px;
          height: 10px;
        }
      }

      &-item:nth-child(2) {
        font-weight: 400;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background-color: $colour-grey-wild-sand;
          left: 15px;
          top: 0;
          height: 100%;
          width: 1px;
        }
      }

      &-item:nth-child(2) div {
        display: flex;
        gap: 10px;
      }

      &-item:nth-child(2) i {
        padding-left: 38px;
        display: block;
        align-self: center;
        position: relative;
      }

      &-item:nth-child(2) span {
        display: block;
        align-self: center;
      }

      &-item:nth-child(2)::before {
        content: '';
        position: absolute;
        background-color: $colour-grey-wild-sand;
        display: block;
        height: 1px;
        width: 23px;
        left: 15px;
        top: 15px;
      }

      &-item:nth-child(n + 3) {
        &::after {
          content: '';
          position: absolute;
          background-color: $colour-grey-wild-sand;
          width: 1px;
          left: 15px;
          bottom: -15px;
          height: 50%;
        }

        &::before {
          content: '';
          position: absolute;
          background-color: $colour-grey-wild-sand;
          width: 1px;
          left: 15px;
          top: -15px;
          height: 50%;
        }
      }

      &-item:nth-child(6) {
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }
    }

    &-info {
      margin-bottom: 15px;
      color: #212c3a;
      font-size: 14px;
      font-weight: 400;
    }

    &-bottom {
      position: relative;
      display: flex;
      align-items: center;
      color: #212c3a;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 0 0 40px;
      border-top: solid 1px #eae5e1;

      &::before {
        content: '';
        display: block;
        width: 30px;
        position: absolute;
        height: 30px;
        left: 0;
        background-image: url('/static/images/bitmap.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 15px;
      }
    }
  }

  &__phone {
    color: #d93d04;
  }
}
